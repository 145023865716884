<template lang="pug">
include ../../configs/template
div.row.px-2.pt-1.pb-2
  div.col-4.text-left
    +data-info('createDate', 'sailorDocument.created_at')
  div.col-4.text-left
    +data-info('fullName', 'fullName')
  div.col-4.text-left(v-if="sailorDocument.last_name_eng")
    +data-info('fullNameEng', 'fullNameEng')
  div.col-4.text-left(v-if="sailorDocument.passport_date_start")
    +data-info('dateEffective', 'sailorDocument.passport_date_start')
  div.col-4.text-left(v-if="sailorDocument.passport_date_end")
    +data-info('dateEnd', 'sailorDocument.passport_date_end')
  div.col-4.text-left
    +data-info('dateBorn', 'getDateFormat(sailorDocument.birthday)')
  div.col-4.text-left
    +data-info('taxNumber', 'sailorDocument.inn')
  div.col-4.text-left
    +data-info('passport', 'sailorDocument.passport')
  div(v-if="sailorDocument.sex").col-4.text-left
    +data-info('sex', 'getDirectoryObject({ value: "sex", id: sailorDocument.sex }).value_ukr')
  div.col-4.text-left
    +data-info('phoneNumber', 'sailorDocument.phone')
  div.col-4.text-left
    +data-info('email', 'sailorDocument.email')
  div.col-4.text-left
    +data-info-status('status', 'sailorDocument.status_document[labelName]', 'getStatus(sailorDocument.status_document.id)')
  div.col-12.text-left(v-if="sailorDocument.status_document.id === rejectNewAccount")
    h2 {{$t('rejectionReasonStatement')}}
    div(v-for="comment in sailorDocument.comments" :key="comment.id")
      div(v-if="comment.additional_info.reason")
        +data-info('reason','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons"})[labelName]')
      div
        +data-info('setComment', 'comment.comment')
      div
        +data-info('created_by', 'comment.created_by.name')
      div
        +data-info('createDate', 'getDateFormat(comment.created_by.date)')
      v-divider
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
import { rejectNewAccount } from '@/configs/constants'

export default {
  name: 'NewAccountsInfo',
  props: {
    sailorDocument: Object
  },
  data () {
    return {
      getDateFormat,
      getStatus,
      rejectNewAccount
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    fullName () {
      return `${this.sailorDocument.last_name} ${this.sailorDocument.first_name} ${this.sailorDocument.middle_name}`
    },
    fullNameEng () {
      return `${this.sailorDocument.last_name_eng} ${this.sailorDocument.first_name_eng}`
    }
  }
}
</script>
